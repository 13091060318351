
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sale } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import logo from "../../assets/img/softlogo.png";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";
import getDateInterval from "../../components/dateInterval";
import {jsPDF} from "jspdf";
import { CSVLink } from "react-csv";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const createPDF = async () => {
    const pdf = new jsPDF("portrait", "px", "a2");
    const data = await document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("softcollections_sales_report.pdf");
    });
};

const SalesReports = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [filterDate, setFilterDate] = useState({from: new Date(), to: new Date()});

    useEffect(() => {
        const today = new Date();
        Sale.salesReport({from: today, to: today}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/sales") )
            }
            setReports(res.sales);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/sales"))
        })
    }, [])

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
    }

    
    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: new Date(e.target.value)});
    }

    const filterSales = (e, date = filterDate) => {
        e.preventDefault();
        Sale.salesReport(date).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/sales") )
            }
            setReports(res.sales);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/sales"))
        })
    }
    
    const filterSalesBySelect = (e) => {
        e.preventDefault();
        setFilterDate(getDateInterval(e.target.value));
        const filterDate = getDateInterval(e.target.value);
        setFilterDate(filterDate);
        filterSales(e, filterDate);
    }

    return  (
        <BodyWrapper page={"salereport"}>
            
            {
                loading && <Loader data={"Sales Report"} />
            }

            <div class="row">
                <div className="col-12 text-left mj-bj-30">
                    <Link to="/sales" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Sales</Link>
                </div>
                <div class="col-sm-5 col-4">
                    <h4 class="page-title">Sales Report</h4>
                </div>
                
                <div className="col-sm-7 col-8 text-right m-b-30">
                    <div className="btn-group btn-group-sm">
                        <CSVLink 
                            data={ 
                                reports.map((product, i) => ({product: product.productName, pieces_sold: product.piecesSold, total_sales: product.totalSales?.toLocaleString()
                                                    }))
                            }  
                            className="btn btn-white"
                            >

                            <i className="fa fa-download fa-lg"></i> CSV Download
                        </CSVLink>
                        <button className="btn btn-white" onClick={createPDF}><i className="fa fa-print fa-lg"></i> Print</button>
                    </div>
                </div>
            </div>

            
            <div className="row filter-row justify-content">
                
                <div className="col-sm-6 col-md-4">
                    <label className="focus-label">Select Sales Period</label>
                    <div className="form-group">
                        <select className="form-control " onChange={filterSalesBySelect}>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="thisweek">This Week</option>
                            <option value="lastweek">Last Week</option>
                            <option value="thismonth">This Month</option>
                            <option value="lastmonth">Last Month</option>
                        </select>
                    </div>
                </div>

            </div>

            <h4>OR Search with Intervals</h4>
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input 
                        className="form-control floating" 
                        type="date" 
                        name="from"
                        // value={filterDate.from.getFullYear() + "-" + (filterDate.from.getMonth()+1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0")} 
                        onChange={dateChange} 
                        />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input 
                        className="form-control floating"
                        type="date"
                        name="to"
                        // value={filterDate.to.getFullYear() + "-" + (filterDate.to.getMonth()+1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0")} 
                        onChange={dateChange} 
                        />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterSales}> Filter </a>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card" id="pdf">
                        <div className="card-body">
                            <div className="row custom-invoice">
                                <div className="col-6 col-sm-6 m-b-20">
                                    <img src={logo} className="inv-logos" alt="Soft Logo" width={150} />
                                    <ul className="list-unstyled">
                                        <br />
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 m-b-20">
                                    <div className="invoice-details">
                                        <h3 className="text-uppercase">Sales Report</h3>
                                        <ul className="list-unstyled">
                                            <li>Today: <span> {new Date().toDateString().slice(8,15).replace(" ", " " + months[new Date().getMonth()] + ", " )}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    <div className="invoices-view">
                                        {/* <span className="text-muted">Payment Details:</span> */}
                                        <ul className="list-unstyled invoice-payment-details">
                                            <li>
                                                <h5>Sales Report For: </h5>
                                            </li>
                                            <li>
                                                {filterDate.from.toDateString().slice(8,15).replace(" ", " " + months[filterDate.from.getMonth()] + ", " )} 
                                                {
                                                    filterDate.from.toDateString() !== filterDate.to.toDateString() &&
                                                    ` --- ${filterDate.to.toDateString().slice(8,15).replace(" ", " " + months[filterDate.to.getMonth()] + ", " )}`
                                                }
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>PRODUCT</th>
                                            <th>PIECES SOLD</th>
                                            <th>TOTAL SALES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reports && reports.map((product, i) => {
                                                return (
                                                    <tr>
                                                        <td>{i+1}</td>
                                                        <td>{product.productName}</td>
                                                        <td className="text-centere">{product.piecesSold}</td>
                                                        <td className="text-centere">&#8358;{product.totalSales?.toLocaleString()}</td>
                                                    
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="row invoice-payment">
                                    <div className="col-sm-7">
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="m-b-20">
                                            <div className="table-responsive no-border">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th>Total Sales:</th>
                                                            <td className="text-rights">&#8358;
                                                            {
                                                            reports.map(r => r.totalSales).length > 0?
                                                            (reports.map(r => r.totalSales).reduce((p, c) => p + c))?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'): 0
                                                        }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div><br /><br /><br />
                                <div className="invoice-info">
                                    {/* <h5>A product of brandblocx.com</h5> */}
                                    <p className="text-muted">A product of brandblocx.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default SalesReports;