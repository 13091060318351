
import './App.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/dataTables.bootstrap4.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";

import {BrowserRouter, Route, Routes} from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Customers from './pages/Customer/Customers';
import Sales from './pages/Sale/AllSales';
import Payments from './pages/Payment/Payments';
import Staffs from './pages/Staff/Staffs';
import Messages from './pages/Message/Messages';
import Expenses from './pages/Expense/Expenses';
import CustomerProfile from './pages/Customer/CustomerProfile';
import Login from './pages/Login';
import SalesDetails from './pages/Sale/SalesDetails';
import EditCustomerProfile from './pages/Customer/EditCustomerProfile';
import StaffProfile from './pages/Staff/StaffProfile';
import AddCustomer from './pages/Customer/AddCustomer';
import RecordSale from './pages/Sale/RecordSale';
import PaymentInfo from './pages/Payment/PaymentInfo';
import EditStaffProfile from './pages/Staff/EditStaffProfile';
import AddStaff from './pages/Staff/AddStaff';
import SendMessage from './pages/Message/SendMessage';
import MessageDetails from './pages/Message/MessageDetails';
import AddExpense from './pages/Expense/AddExpense';
import ForgetPassword from './pages/ForgetPassword';
import MyProfile from './pages/Staff/MyProfile';
import EditMyProfile from './pages/Staff/EditMyProfile';
import CustomerCategories from './pages/Customer/Categories';


// Staffs Pages Imports 
import StaffDashboard from './pages/StaffPages/Dashboard';
import StaffCustomers from './pages/StaffPages/Customer/Customers';
import StaffAddCustomer from './pages/StaffPages/Customer/AddCustomer';
import StaffCustomerProfile from './pages/StaffPages/Customer/CustomerProfile';
import StaffEditCustomerProfile from './pages/StaffPages/Customer/EditCustomerProfile';
import StaffSales from './pages/StaffPages/Sale/Sales';
import StaffRecordSale from './pages/StaffPages/Sale/RecordSale';
import StaffSalesDetails from './pages/StaffPages/Sale/SalesDetails';
import StaffPayments from './pages/StaffPages/Payments';
import StaffPaymentInfo from './pages/StaffPages/PaymentInfo';
import StaffMessages from './pages/StaffPages/Message/Messages';
import StaffSendMessage from './pages/StaffPages/Message/SendMessage';
import StaffMessageDetails from './pages/StaffPages/Message/MessageDetails';
import StaffMyProfile from "./pages/StaffPages/MyProfile";
import EditMyStaffProfile from './pages/StaffPages/EditMyProfile';
import StaffCustomerCategories from './pages/StaffPages/Customer/Categories';

import StaffProducts from './pages/StaffPages/Product/Products';
import StaffProductDetails from './pages/StaffPages/Product/ProductDetails';
import StaffEditProduct from './pages/StaffPages/Product/EditProduct';
import StaffAddProduct from './pages/StaffPages/Product/AddProduct';

import Products from './pages/Product/Products';
import ProductDetails from './pages/Product/ProductDetails';
import EditProductDetails from './pages/Product/EditProduct';
import AddProduct from './pages/Product/AddProduct';

import SalesPrint from './pages/Sale/SalesPrint';
import StaffSalesPrint from './pages/StaffPages/Sale/SalesPrint';

import SalesReports from './pages/Sale/SalesReport';
import StaffSalesReports from './pages/StaffPages/Sale/SalesReport';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgetPassword />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/myprofile/edit' element={<EditMyProfile />} />

        <Route path='/customers' element={<Customers />} />
        <Route path='/customers/categories' element={<CustomerCategories />} />
        <Route path='/customers/:id' element={<CustomerProfile />} />
        <Route path='/customers/:id/edit' element={<EditCustomerProfile />} />
        <Route path='/add-customer' element={<AddCustomer />} />

        <Route path='/sales' element={<Sales />} />
        <Route path='/sales-report' element={<SalesReports />} />
        <Route path='/sales/:id' element={<SalesDetails />} />
        <Route path='/record-sale' element={<RecordSale />} />

        <Route path='/payments' element={<Payments />} />
        <Route path='/payments/:id' element={<PaymentInfo />} />
        <Route path='/payment-print/:id' element={<SalesPrint />} />

        <Route path='/staffs' element={<Staffs />} />
        <Route path='/staffs/:id' element={<StaffProfile />} />
        <Route path='/staffs/:id/edit' element={<EditStaffProfile />} />
        <Route path='/add-staff' element={<AddStaff />} />

        <Route path='/messages' element={<Messages />} />
        <Route path='/messages/:id' element={<MessageDetails />} />
        <Route path='/send-message' element={<SendMessage />} />

        <Route path='/expenses' element={<Expenses />} />
        <Route path='/record-expense' element={<AddExpense />} />

        
        <Route path='/products' element={<Products />} />
        <Route path='/products/:id' element={<ProductDetails />} />
        <Route path='/products/:id/edit' element={<EditProductDetails />} />
        <Route path='/add-product' element={<AddProduct />} />


        {/* Staffs Routes  */}
        <Route path='/staff' element={<StaffDashboard />} />
        <Route path='/staff/dashboard' element={<StaffDashboard />} />
        <Route path='/staff/myprofile' element={<StaffMyProfile />} />
        <Route path='/staff/myprofile/edit' element={<EditMyStaffProfile />} />

        <Route path='/staff/customers' element={<StaffCustomers />} />
        <Route path='/staff/customers/categories' element={<StaffCustomerCategories />} />
        <Route path='/staff/customers/:id' element={<StaffCustomerProfile />} />
        <Route path='/staff/customers/:id/edit' element={<StaffEditCustomerProfile />} />
        <Route path='/staff/add-customer' element={<StaffAddCustomer />} />
        
        <Route path='/staff/sales' element={<StaffSales />} />
        <Route path='/staff/sales/:id' element={<StaffSalesDetails />} />
        <Route path='/staff/record-sale' element={<StaffRecordSale />} />
        <Route path='/staff/sales-report' element={<StaffSalesReports />} />

        <Route path='/staff/payments' element={<StaffPayments />} />
        <Route path='/staff/payments/:id' element={<StaffPaymentInfo />} />
        <Route path='/staff/payment-print/:id' element={<StaffSalesPrint />} />

        <Route path='/staff/messages' element={<StaffMessages />} />
        <Route path='/staff/messages/:id' element={<StaffMessageDetails />} />
        <Route path='/staff/send-message' element={<StaffSendMessage />} />
        
        <Route path='/staff/products' element={<StaffProducts />} />
        <Route path='/staff/products/:id' element={<StaffProductDetails />} />
        <Route path='/staff/products/:id/edit' element={<StaffEditProduct />} />
        <Route path='/staff/add-product' element={<StaffAddProduct />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
