
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Customer, Message } from "../../../api";
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import Toast from "../../../components/Toast";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const StaffSendMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [generalMessage, setGeneralMessage] = useState(true);
    const [categoryMessage, setCategoryMessage] = useState(false);
    const [category, setCategory] = useState({});
    const [form, setForm] = useState({reason: "", type: "Text Message", body: "", subject: "", schedule: "now", scheduleDate: ""});
    const [recUser, setRecUser] = useState({});
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const cid = location.search && location.search.indexOf("cid") > -1 && location.search.split("=")[1];
    const catid = location.search && location.search.indexOf("catid") > -1 && location.search.split("=")[1];

    useEffect(() => {
        if(cid) {
            setGeneralMessage(false);
            Customer.customerProfile({customerID: cid}).then(res => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message})
                    .then(() => navigate("/staff/customers"));
                }
                setRecUser(res.customer);
            }).catch(err => {
                Toast.fire({icon: "error", title: "Network error"})
                .then(() => navigate("/staff/customers"));
            })
        }

        if (catid) {
            setCategoryMessage(true);
            Customer.getCategories().then((res) => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message})
                    .then(() => navigate("/staff/customers"));
                }
                const category = res.categories.find(cat => cat.id == catid);
                setCategory(category);
            })
        }
    }, []);

    const submitMessageForm = (e) => {
        e.preventDefault();
        setLoading(true);
        if (categoryMessage) {
            Message.sendCategoryMessage({...form, recipient: catid}).then(res => {
                setLoading(false);
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                Toast.fire({ icon: "success", title: "Message has been sent to customers"})
                .then(() => navigate("/staff/messages"));
            }).catch(err => {
                setLoading(false);
                Toast.fire({icon: "error", title: "Network error. Go back a step and retry."});
            })
        } else {
            Message.sendMessage({...form, recipient: generalMessage ? "all customers" : recUser.id}).then(res => {
                setLoading(false);
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                Toast.fire({icon: "success", title: "Message has been sent to customers"})
                .then(() => navigate("/staff/messages"));
            }).catch(err => {
                setLoading(false);
                Toast.fire({icon: "error", title: "Network error. GO back a step and retry."});
            })
        }
    }

    return  (
        <BodyWrapper page={"message"}>
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <h4 class="page-title">Send Message</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <form onSubmit={submitMessageForm}>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Message Recipient(s)</label>
                                    <input type="text" className="form-control" readOnly value={categoryMessage ? category.name + " Category" : generalMessage ? "ALL Customers" : recUser.fname + " " + recUser.lname} />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Message Reason</label>
                                    <input type="text" className="form-control" name="reason" value={form.reason} onChange={inputChange} />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Message Type</label>
                                    <select class="form-control" name="type" onChange={inputChange}>
                                        {/* <option value={"Whatsapp"}>Whatsapp</option> */}
                                        <option value={"Text Message"}>Text Message</option>
                                        <option value={"Email"}>Email</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                form.type === "Email" && 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email Subject</label>
                                        <input type="text" className="form-control" name="subject" value={form.subject} onChange={inputChange} />
                                    </div>
                                </div>
                            }
                        </div>

                        {
                            form.type === "Email" ?
                            <ReactQuill  
                            theme="snow" 
                            style={{height: "150px", marginBottom: "50px"}}
                            onChange={(val) => setForm({...form, body: val})} 

                            />
                            :
                            <div class="form-group">
                                <label>Message</label>
                                <textarea cols="30" rows="4" class="form-control" name="body" value={form.body} onChange={inputChange}></textarea>
                            </div>
                        }


                        <div className="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>When do you want to send message?</label>
                                    <select class="form-control" name="schedule" onChange={inputChange}>
                                        <option value={"now"}>Send Now</option>
                                        <option value={"later"}>Schedule Message</option>
                                    </select>
                                </div>
                            </div>
                            {
                                form.schedule === "later" && 
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Select date and time</label>
                                        <input type="datetime-local" className="form-control" name="scheduleDate" value={form.scheduleDate} onChange={inputChange} />
                                    </div>
                                </div>
                            }
                        </div>

                        <div class="m-t-20 text-center">
                            <button class="btn btn-primary submit-btn">{loading ? "Sending..." : (generalMessage ? "Send to customers" : "Send to " + recUser.fname + " " + recUser.lname )}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default StaffSendMessage;