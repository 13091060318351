
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Expense } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";

const AddExpense = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({item: "", amount: 0, purchasedFrom: "", paymentMethod: "", purchaseDate: "" });
    const [receiptFile, setReceiptFile] = useState({});
    const [loading, setLoading] = useState(false);
    
    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }
    const fileChange = e => {
        const uploadedFile = e.target.files[0];
        setReceiptFile(uploadedFile);
    }

    const recordExpense = (e) => {
        e.preventDefault();
        setLoading(true);
        const expForm = new FormData();
        for (const key in form) {
            if (form.hasOwnProperty(key)) {
                expForm.append(key, form[key]);
            }
        }
        expForm.append("receipt", receiptFile);
        Expense.addExpense(expForm).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            } else if(res.warning) {
                return Toast.fire({icon: "warning", title: res.message});
            }
            Toast.fire({icon: "success", title: res.message}).then(() => navigate("/expenses"));
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", message: "Network error. Go back a step and retry."})
        })
    }
    

    return  (
        <BodyWrapper page={"expense"}>
            
            <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <h4 class="page-title">Record New Expense</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <form onSubmit={recordExpense}>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Item Name</label>
                                        <input class="form-control" type="text" name="item" value={form.item} onChange={inputChange} />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Purchased From</label>
                                        <input class="form-control" type="text" name="purchasedFrom" value={form.purchasedFrom} onChange={inputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Amount in Naira</label>
                                        <input class="form-control" type="number" name="amount" value={form.amount} onChange={inputChange} />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Payment Method</label>
                                        <select class="form-control" name="paymentMethod" value={form.paymentMethod} onChange={inputChange}>
                                            <option value={"Cash"}>Cash</option>
                                            <option value={"Bank Transfer"}>Transfer</option>
                                            <option value={"ATM Online Payment"}>ATM Online Payment</option>
                                            <option value={"ATM POS Payment"}>ATM POS Payment</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Purchase Date</label>
                                        <div>
                                            <input class="form-control" type="date" name="purchaseDate" value={form.purchaseDate} onChange={inputChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Payment Receipt (Max filesize allowed is 10MB)</label>
                                        <input class="form-control" type="file" name="receipt" onChange={fileChange} />
                                    </div>
                                </div>
                            </div>
                            <div class="attach-files">
                                
                            </div>
                            <div class="m-t-20 text-center">
                                <button class="btn btn-primary submit-btn">Create Expense</button>
                            </div>
                        </form>
                    </div>
                </div>
        </BodyWrapper>
    )
}

export default AddExpense;