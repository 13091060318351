import { Link } from "react-router-dom";
const StaffSideNav = ({activePage}) => {
    return (
        
        <div className="sidebar mini-sidebar" id="sidsebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                        <li className="menu-title"></li>
                        <li  className={(activePage && activePage == "dashboard") ? "active" : null}>
                            <Link to="/staff"><i className="fa fa-dashboard"></i> <span>Dashboard</span></Link>
                        </li>
                        <li className={(activePage && activePage == "customer") ? "active" : null}>
                            <Link to="/staff/customers"><i className="fa fa-users"></i> <span>Customers</span></Link>
                        </li>
                        <li className={(activePage && activePage == "product") ? "active" : null}>
                            <Link to="/staff/products"><i className="fa fa-product-hunt"></i> <span>Products</span></Link>
                        </li>
                        <li className={(activePage && activePage == "sale") ? "active" : null}>
                            <Link to="/staff/sales"><i className="fa fa-handshake-o"></i> <span>Sales</span></Link>
                        </li>
                        <li className={(activePage && activePage == "payment") ? "active" : null}>
                            <Link to="/staff/payments"><i className="fa fa-money"></i> <span>Payments</span></Link>
                        </li>
                        <li className={(activePage && activePage == "message") ? "active" : null}>
                            <Link to="/staff/messages"><i className="fa fa-comment"></i> <span>Messages</span></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


export default StaffSideNav;
