import { Link, useNavigate } from "react-router-dom";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import user from "../../assets/img/user.jpg";
import { Customer } from "../../api";
import { useEffect, useState } from "react";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";

const CustomerCategories = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        Customer.getCategories({}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(() => navigate("/"));
            }
            
            setCategories(res.categories);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh"}).then(() =>navigate("/"));
        })
    }, [])

    const deleteCategory = ({id,name}) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete ${name}'s category? It will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(res => {
            if (res.isConfirmed) {
                Customer.deleteCategory({cid: id}).then(res => {
                    if(res.error) {
                        return Toast.fire({icon: "error", title: res.message})
                    }
                    Toast.fire({icon: "success", title: "Category deleted successfully!"}).then(() => {
                        window.location.reload();
                    })
                })
            }
        })
    }

    const addCategory = () => {
        Swal.fire({
            input: "text",
            title: "Enter Category name",
            showCancelButton: true,
            confirmButtonText: "Create Category",
        }).then(res => {
            if (res.isConfirmed) {
                Customer.addCategory({name: res.value}).then(res => {
                    if(res.error) {
                        return Toast.fire({icon: "error", title: res.message});
                    }
                    Toast.fire({icon: "success", title: "Category added successfully"});
                    Customer.getCategories({}).then(res => {
                        if(res.error) {
                            return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
                        }
                        setCategories(res.categories);
                    })
                }).catch((err) => {
                    console.log(err);
                    Toast.fire({icon: "error", title: "Network error. Please go back a setp and refresh"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            
            {
                loading && <Loader data={"Customer Categories"} />
            }

            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Customer Categories</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <button className="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#c4e00a", color: "#222"}}onClick={addCategory}><i className="fa fa-plus"></i> Add Category</button>
                </div>
            </div>
            <div className="row doctor-grid">
                {
                    categories ?
                    categories.map(category => {
                        return (
                            <div className="col-md-4 col-sm-4  col-lg-3">
                                <Link to="#">
                                    <div className="profile-widget">
                                        <h3 className="doctor-name text-ellipsis"><Link to={"/customers?cid=" + category.id}>{category.name}</Link></h3>
                                        {/* <br /> */}
                                        <div className="doc-profs">
                                            <h5>{category.customers} customers</h5>
                                        </div>
                                        <Link className="dropdown-items" to={"/send-message?catid=" + category.id} title="Message category"><i className="fa fa-comment m-r-5"></i> </Link>
                                        <a href="#" onClick={() => deleteCategory({id: category.id, name: category.name})}><i className="fa fa-trash"></i></a>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                    :
                    <p></p>
                }

            </div>
            {/* <div className="row">
                <div className="col-sm-12">
                    <div className="see-all">
                        <a className="see-all-btn" href="javascript:void(0);">Load More</a>
                    </div>
                </div>
            </div> */}
        </BodyWrapper>
    )
}

export default CustomerCategories;