

const Loader = ({data}) => {
    
    return (
        <div class="loader-container">
            <div>
                <p>Loading Soft's {data}...</p>
                <div class="rotator"></div>
            </div>
        </div>
    )
}

export default Loader;