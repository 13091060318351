import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";

import { Link } from "react-router-dom";
// const UserInfoDropdown = ({id}) => {
//     const [admin, setAdmin] = useState(true);

//     useEffect(() => {
//         if(Cookies.get("sc_staff_role") !== "admin") {
//             setAdmin(false);
//         }
//     }, [])
    
//     return (
//         <div className="dropdown dropdown-action">
//             <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
//             {
//                 admin ?
//                 <div className="dropdown-menu dropdown-menu-right">
//                     <Link className="dropdown-item" to={`/record-sale?cid=${id}`}><i className="fa fa-plus m-r-5"></i> Record Sale</Link>
//                     <Link className="dropdown-item" to={"/customers/" + id}><i className="fa fa-eye m-r-5"></i> View Profile</Link>
//                     <Link className="dropdown-item" to={"/send-message?cid=" + id} data-toggle="modal" data-target="#delete_appointment"><i className="fa fa-comment m-r-5"></i> Send Message</Link>
//                 </div>
//                 :
//                 <div className="dropdown-menu dropdown-menu-right">
//                     <Link className="dropdown-item" to={`/staff/record-sale?cid=${id}`}><i className="fa fa-plus m-r-5"></i> Record Sale</Link>
//                     <Link className="dropdown-item" to={"/staff/customers/" + id}><i className="fa fa-eye m-r-5"></i> View Profile</Link>
//                     <Link className="dropdown-item" to={"/staff/send-message?cid=" + id} data-toggle="modal" data-target="#delete_appointment"><i className="fa fa-comment m-r-5"></i> Send Message</Link>
//                 </div>
//             }
//         </div>
//     )
// }


const UserInfoDropdown = ({id}) => {
    const [admin, setAdmin] = useState(true);

    useEffect(() => {
        if(Cookies.get("sc_staff_role") !== "admin") {
            setAdmin(false);
        }
    }, [])
    
    return (
        <div className="dropdown dropdown-action">
            {
                admin ?
                <div >
                    <Link className="dropdown-items" to={`/record-sale?cid=${id}`}><i className="fa fa-plus m-r-5"></i> </Link>
                    <Link className="dropdown-items" to={"/customers/" + id}><i className="fa fa-eye m-r-5"></i> </Link>
                    <Link className="dropdown-items" to={"/send-message?cid=" + id} ><i className="fa fa-comment m-r-5"></i> </Link>
                </div>
                :
                <div >
                    <Link className="dropdown-itemx" to={`/staff/record-sale?cid=${id}`}><i className="fa fa-plus m-r-5"></i></Link>
                    <Link className="dropdown-itemx" to={"/staff/customers/" + id}><i className="fa fa-eye m-r-5"></i> </Link>
                    <Link className="dropdown-itemx" to={"/staff/send-message?cid=" + id}><i className="fa fa-comment m-r-5"></i></Link>
                </div>
            }
        </div>
    )
}

export default UserInfoDropdown;