
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Customer } from "../../../api";
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import Toast from "../../../components/Toast";
import Loader from "../../../components/Loader";

const StaffEditCustomerProfile = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({fname: "", lname: "", phone: "", phone2: "", email: "", gender: "", address: "", city: "", state: "", state: "", occupation: ""});
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const customerID = useLocation().pathname.split("/")[3];
    useEffect(() => {
        Customer.customerProfile({customerID}).then(res => {
            setPageLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/staff/customers"));
            }
            setForm(res.customer);
        }).catch(err => {
            setPageLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/staff/customers"));
        })
    }, [])

    const updateCustomer = (e) => {
        e.preventDefault();
        setLoading(true);
        Customer.updateCustomer({...form, customerID}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message})
            }
            Toast.fire({icon: "success", title: "Customer account updated succesfully"}).then(() =>navigate("/staff/customers/" + customerID));
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            
            {
                pageLoading && <Loader data={"Sales"} />
            }

            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/staff/customers/" + customerID} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to profile</Link>
                </div>
                <div className="col-sm-12">
                    <h4 className="page-title">Update Customer Profile</h4>
                </div>
            </div>
            <form onSubmit={updateCustomer}>
                <div className="card-box">
                    <h3 className="card-title">Basic Informations</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-basc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">First Name</label>
                                            <input type="text" className="form-control floating" name="fname" value={form.fname} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Last Name</label>
                                            <input type="text" className="form-control floating" name="lname" value={form.lname} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Gender</label>
                                            <select className="select form-control floating" name="gender" value={form.gender} onChange={inputChange}>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Occupation</label>
                                            <input type="text" className="form-control floating"  name="occupation" value={form.occupation} onChange={inputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-box">
                    <h3 className="card-title">Contact Informations</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Phone Number</label>
                                <input type="tel" className="form-control floating" name="phone" value={form.phone} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Phone Number 2</label>
                                <input type="tel" className="form-control floating" name="phone2" value={form.phone2} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">Email</label>
                                <input type="email" className="form-control floating" name="email" value={form.email} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group form-focus">
                                <label className="focus-label">Address</label>
                                <input type="text" className="form-control floating" name="address" value={form.address} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">City</label>
                                <input type="text" className="form-control floating" name="city" value={form.city} onChange={inputChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-focus">
                                <label className="focus-label">State</label>
                                <select className="form-control" value={form.state} name="state" onChange={inputChange}>
                                    <option value="">- Select -</option>
                                    <option value="Abuja FCT">Abuja FCT</option>
                                    <option value="Abia">Abia</option>
                                    <option value="Adamawa">Adamawa</option>
                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                    <option value="Anambra">Anambra</option>
                                    <option value="Bauchi">Bauchi</option>
                                    <option value="Bayelsa">Bayelsa</option>
                                    <option value="Benue">Benue</option>
                                    <option value="Borno">Borno</option>
                                    <option value="Cross River">Cross River</option>
                                    <option value="Delta">Delta</option>
                                    <option value="Ebonyi">Ebonyi</option>
                                    <option value="Edo">Edo</option>
                                    <option value="Ekiti">Ekiti</option>
                                    <option value="Enugu">Enugu</option>
                                    <option value="Gombe">Gombe</option>
                                    <option value="Imo">Imo</option>
                                    <option value="Jigawa">Jigawa</option>
                                    <option value="Kaduna">Kaduna</option>
                                    <option value="Kano">Kano</option>
                                    <option value="Katsina">Katsina</option>
                                    <option value="Kebbi">Kebbi</option>
                                    <option value="Kogi">Kogi</option>
                                    <option value="Kwara">Kwara</option>
                                    <option value="Lagos">Lagos</option>
                                    <option value="Nassarawa">Nassarawa</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Ogun">Ogun</option>
                                    <option value="Ondo">Ondo</option>
                                    <option value="Osun">Osun</option>
                                    <option value="Oyo">Oyo</option>
                                    <option value="Plateau">Plateau</option>
                                    <option value="Rivers">Rivers</option>
                                    <option value="Sokoto">Sokoto</option>
                                    <option value="Taraba">Taraba</option>
                                    <option value="Yobe">Yobe</option>
                                    <option value="Zamfara">Zamfara</option>
                                    <option value="outside_nigeria">Outside Nigeria</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center m-t-20">
                    <button className="btn btn-primary submit-btn" type="submit" style={{color: "#000"}}>{loading ? "Saving..." : "Save"}</button>
                </div>
            </form>
        </BodyWrapper>
    )
}

export default StaffEditCustomerProfile;