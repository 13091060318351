
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sale } from "../../../api";
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import Toast from "../../../components/Toast";
import Loader from "../../../components/Loader";

const SalesDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [sale, setSale] = useState({});
    const saleID = location.search.split("=")[1];
    const saleRef = location.pathname.split("/")[3];

    useEffect(() => {
        if (!saleID || !saleRef) {
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/staff/sales") ) ;
        }
        Sale.saleDetails({saleID, saleRef}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/staff/sales") )
            }
            setSale(res.saleDetails);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/staff/sales"))
        })
    }, [])

    return  (
        <BodyWrapper page={"sale"}>
            
            {
                loading && <Loader data={"Sale Details"} />
            }

            <div class="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/staff/sales" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Sales</Link>
                    </div>
                <div class="col-sm-5 col-4">
                    <h4 class="page-title">Sales Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row custom-invoice">
                                <div class="col-6 col-sm-6 m-b-20">
                                    <div class="invoice-detals">
                                        <h3 class="text-uppercase">Sales #{sale.salesid}</h3>
                                        <ul class="list-unstyled">
                                            <li>Date: <span>{new Date(sale.date_recorded).toDateString()}</span></li>
                                            <li>Time: <span>{new Date(sale.date_recorded).toLocaleTimeString()}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-lg-6 m-b-20">
                                    
                                        <h5>Purchase By:</h5>
                                        <ul class="list-unstyled">
                                            <li>
                                                <h5><strong><Link to={"/staff/customers/" + sale.customerid}>{sale.c_fname} {sale.c_lname}</Link></strong></h5>
                                            </li>
                                            <li>{sale.adress}</li>
                                            <li>{sale.city && sale.city + ", "}{sale.state}</li>
                                            <li>{sale.phone}</li>
                                        </ul>
                                    
                                </div>
                                <div class="col-sm-6 col-lg-6 m-b-20">
                                    <div class="invoices-view">
                                        <span class="text-muted"><strong>Recorded By:</strong></span>
                                        <ul class="list-unstyled invoice-payment-details">
                                            <li>{sale.s_fname} {sale.s_lname}</li>
                                            <li><br /></li>

                                            <li><strong>Payment:</strong></li>
                                            <li>Status <span>{sale.payment && (sale.payment.status[0].toUpperCase() + sale.payment.status.slice(1) )}</span></li>
                                            <li>Payer Name: <span>{sale.payment && sale.payment.payer_name}</span></li>
                                            <li>Bank: <span>{sale.payment && sale.payment.payer_bank}</span></li>
                                            <li>Method: <span>{sale.payment && sale.payment.mode}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ITEM</th>
                                            <th>{sale.version === 2 ? "COST" : "DESCRIPTION"}</th>
                                            <th>QUANTITY</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sale.version === 2 ?
                                        sale.items.map(item => {
                                            return (
                                            <tr>
                                                <td></td>
                                                <td>{item.p_name}</td>
                                                <td>&#8358;{item.price.toLocaleString()}</td>
                                                <td>{item.quantity}</td>
                                                <td>&#8358;{Math.ceil(item.quantity * item.price).toLocaleString()}</td>
                                            </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td></td>
                                            <td>{sale.products_purchase}</td>
                                            <td>{sale.purchase_description}</td>
                                            <td>{sale.products_quantity}</td>
                                            <td>&#8358;{sale.payment && sale.payment.amount}</td>
                                        </tr>
                                    }
                                    
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div class="row invoice-payment">
                                    <div class="col-sm-7">
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="m-b-20">
                                            <h6>Total due</h6>
                                            <div class="table-responsive no-border">
                                                <table class="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td class="text-right">&#8358;{sale.payment && sale.payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tax: <span class="text-regular">(0%)</span></th>
                                                            <td class="text-right">&#8358;0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total:</th>
                                                            <td class="text-right text-primary">
                                                                <h5>&#8358;{sale.payment && sale.payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice-info">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default SalesDetails;