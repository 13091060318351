
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import user from "../../assets/img/user.jpg";
import { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Toast from "../../components/Toast";
import { Message } from "../../api";
import { useState } from "react";

const MessageDetails = () => {
    const navigate = useNavigate();
    const msgID = useLocation().pathname.split("/")[2];
    const [message, setMessage] = useState({});

    useEffect(() => {
        if(!msgID) {
            return Toast.fire({icon: "error", title: "Invalid link followed. Go back a step and refresh"})
            .then(() => navigate("/messsages"))
        }

        Message.messageDetails({msgID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."})
                .then(() => navigate("/messsages"))
            }
            setMessage(res.messageDetail);
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."})
            .then(() => navigate("/messsages"))
        })

    }, []);

    const approveMessage = () => {
        Message.updateMessageStatus({messageID: msgID, status: "Sent"}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Message approved and sent."}).then(() => {
                Message.messageDetails({msgID}).then(res => {
                    setMessage(res.messageDetail);
                })
            }).catch(err=> {
                Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."});
            })
        })
    }
    
    const deleteMessage = () => {
        Message.updateMessageStatus({messageID: msgID, status: "Delete"}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Message deleted"}).then(() => {
                navigate("/messages");
            }).catch(err=> {
                Toast.fire({icon: "error", title: "Network error. Go back a step and refresh."});
            })
        })
    }

    return  (
        <BodyWrapper page={"message"}>
            <div class="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/messages" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Messages</Link>
                    </div>
                <div class="col-sm-12">
                    <h4 class="page-title"> Message Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-box">
                        <div class="mailview-content">
                            <div class="mailview-header">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <div class="text-ellipsis m-b-10">
                                            <span class="mail-view-title">{message.message_reason}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="mail-view-action">
                                            <div class="btn-group">
                                                {
                                                    message.status !== "Sent" && 
                                                    <button type="button" class="btn btn-white btn-sm" data-toggle="tooltip" title="Approve" onClick={approveMessage}> <i class="fa fa-check"></i> Approve</button>
                                                }
                                                <button type="button" class="btn btn-white btn-sm" data-toggle="tooltip" title="Delete" onClick={deleteMessage}> <i class="fa fa-trash-o"></i> Delete</button>
                                            </div>
                                            {/* <button type="button" class="btn btn-white btn-sm" data-toggle="tooltip" title="Print"> <i class="fa fa-print"></i></button> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="sender-info">
                                    <div class="sender-img">
                                        <img width="40" alt="" src={user} class="rounded-circle" />
                                    </div>
                                    <div class="receiver-details float-left">
                                        <span class="sender-name">Sent by <b>{message.fname + " " + message.lname} </b></span>
                                        <span class="receiver-name">
                                            to <span><b>{message.general ? "All customers" : message.customer?.fname + " " + message.customer?.lname}</b></span>
                                            {/* , <span>Paul</span> */}
                                        </span>
                                        <br />
                                        <span>
                                            Type: 
                                            <b> {message.message_type}</b>
                                        </span>
                                    </div>
                                    <div class="mail-sent-time">
                                        <span class="mail-time">Initiated: {new Date(message.date_initiated).toLocaleString()}</span>
                                        <br/>
                                        <span class="mail-time">Sent: {message.status === "Sent" && new Date(message.date_sent).toLocaleString()}</span>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="mailview-inner" style={{minHeight: "200px"}}>
                                <p>{message.message_body}</p>
                                
                            </div>
                        </div>
                        <div class="mail-attachments">
                            {/* <p><i class="fa fa-paperclip"></i> 2 Attachments - <a href="#">View all</a> | <a href="#">Download all</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default MessageDetails;