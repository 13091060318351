
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/Loader";

import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Customer, Staff } from "../../api";
import Toast from "../../components/Toast";
import UserInfoDropdown from "../../components/UserInfoDropdown";
import Swal from "sweetalert2";

const columns = [
    { name: 'Name', selector: row => row.name.length > 16 ? row.name.slice(0,15).trim() + "..." : row.name, sortable: true },
    { name: 'Address', selector: row => row.address.length > 16 ? row.address.slice(0,15).trim() + "..." : row.address, sortable: true },
    { name: 'Phone', selector: row => row.phone, sortable: true },
    { name: 'Email', selector: row => row.email.length > 16 ? row.email.slice(0,15).trim() + "..." : row.email, sortable: true },
    { name: 'Purchases', selector: row => row.purchases, sortable: true },
    { name: 'Last Purchase Date', selector: row => row.last_purchase, sortable: true },
    { name: 'Action', selector: row => row.action, },
];

const Customers = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [customerFilter, setCustomerFlter] = useState([]);

    const [filterData, setFilterData] = useState("name");
    const [filterSearchInput, setFilterSearchInput] = useState("");
    const [filterSearch, setFilterSearch] = useState("");
    const [filter, setFlter] = useState(false);

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [totalCustomersCount, setTotalCustomersCount] = useState(0);
    const [perPage, setPerPage] = useState(100);
    const [tableLoading, setTableLoading] = useState(false);

    const fetchCustomers = async page => {
        setTableLoading(true);
        const response = await Customer.getCustomers({page, perPage, filter: filter ? {type: filterData, data: filterSearch} : false});
        console.log(filter, {type: filterData, data: filterSearch}, response);
        setCustomers(response.customers);
        setCustomersData(response.customers.map(customer => ({
            ...customer, name: customer.fname + " " + customer.lname, 
            action: <UserInfoDropdown id={customer.id}/>, 
            last_purchase: new Date(customer.last_purchase).toLocaleDateString()
        })));
        if (filter) {
            setCustomerFlter(response.customers.map(customer => ({
                ...customer, name: customer.fname + " " + customer.lname, 
                action: <UserInfoDropdown id={customer.id}/>, 
                last_purchase: new Date(customer.last_purchase).toLocaleDateString()
            })));
        }
        setTableLoading(false);
      };
      
      const handlePageChange = page => {
          fetchCustomers(page);
      };

      const handlePerRowsChange = async (newPerPage, page) => {
          setTableLoading(true);
          const response = await Customer.getCustomers({page, perPage, filter: filter ? {type: filterData, data: filterSearch} : false});
          setCustomers(response.customers);
        setCustomersData(response.customers.map(customer => ({
            ...customer, name: customer.fname + " " + customer.lname, 
            action: <UserInfoDropdown id={customer.id}/>, 
            last_purchase: new Date(customer.last_purchase).toLocaleDateString()
        })));
          setPerPage(newPerPage);
          setTableLoading(false);
      };


    const searchCustomer = () => {
        setLoading(true);
        if(filterSearchInput.length> 0) {
            setFlter(true);
            setFilterSearch(filterSearchInput);
            // Customer.getCustomers({page: 1, perPage, filter: filter ? {type: filterData, data: filterSearch} : false})
            // .then(response => {
            //     console.log(response.customers);
            //     console.log(filter);
            //     setCustomerFlter(response.customers.map(customer => ({
            //         ...customer, name: customer.fname + " " + customer.lname, 
            //         action: <UserInfoDropdown id={customer.id}/>, 
            //         last_purchase: new Date(customer.last_purchase).toLocaleDateString()
            //     })));
            // });
            
            // if (filterData === "name") {
            //     results = customersData.filter(customer => customer.name.toLowerCase().indexOf(filterSearch.toLowerCase())> -1);
            // } else if (filterData === "location") {
            //     results = customersData.filter(customer => {
            //         return  customer.address.toLowerCase().indexOf(filterSearch.toLowerCase()) > -1 || customer.state.toLowerCase().indexOf(filterSearch.toLowerCase()) > -1
            //     });
            // } else {
            //     results = customersData.filter(customer => customer.phone.toString().indexOf(filterSearch.toLowerCase())> -1);
            // }
            // setCustomerFlter(results);
        } else {
            setFlter(false);
        }
        setLoading(false);
    }

    
    useEffect(() => {
        fetchCustomers(1)
    }, [filter, filterSearch]);

    
    useEffect(() => {

        fetchCustomers(1);
        const cid = location.search;
        if(cid && cid.indexOf("cid") > -1) {
            const id = cid.split("=")[1];
            if(id) {
                console.log(id);
                let categoryResults = [];
                categoryResults = customersData.filter(customer => customer.category == id);
                setFlter(true);
                setCustomerFlter(categoryResults);
            }
        }

        Customer.allCustomersCount().then(res => {
            setTotalCustomersCount(res.customers[0]['count(id)']);
        })


        // Load customers categories
        Customer.getCategories().then(res => {
            if (res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and reload."}).then(() => {
                    navigate("/");
                })
            }
            setCategories(res.categories);
        })

    }, [])

    const filterCustomerCategory = (e) => {
        if (e.target.value === "all") {
            return setFlter(false);
        }
        
        let categoryResults = [];
        categoryResults = customersData.filter(customer => customer.category == e.target.value);
        setFlter(true);
        setCustomerFlter(categoryResults);
    }

    const handleSelectChange = (res) => {
       setSelectedCustomers(res.selectedRows);
    }

    const addToCategory = () => {
        Swal.fire({
            title: "Add " + selectedCustomers.length + " to category",
            input: "select",
            inputOptions: {Categories: Object.fromEntries(categories.map(c => [c.id, c.name]) ) },
            confirmButtonText: "Add to category",
            
        }).then(val => {
            const data = {customers: selectedCustomers.map(c => c.id), categoryID: val.value }
            Customer.addCustomersToCategory(data).then(res => {
                console.log(res);
                if (res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                Toast.fire({icon: "success", title: res.message});
            }).catch(err => {
                return Toast.fire({icon: "error", title: "Network error. GO back a step and try again."});
            })
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            {
                loading && <Loader data={"Customers"} />
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Customers</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <Link to={"/customers/categories"} className="btn btn-primary btn-sm"><i className="fa fa-users"></i> Categories</Link>
                    &nbsp; &nbsp;
                    <Link to="/add-customer" className="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#c4e00a", color: "#222"}}><i className="fa fa-plus"></i> Add New Customer</Link>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-12 text-right m-b-1">
                    <div className="btn-group btn-group-sm">
                        {/* <button className="btn btn-white">CSV</button> */}
                        <CSVLink 
                        data={ 
                            filter ? 
                            customerFilter.map(customer => ({first_name: customer.fname, last_name: customer.lname, email: customer.email, address: customer.address, phone_number: customer.phone, phone_number2: customer.phone2, number_of_purchase: customer.purchases, date_registered: customer.date_added, last_purchase_date: customer.last_purchase, staff_name: customer.name}))
                            : 
                            customersData.map(customer => ({first_name: customer.fname, last_name: customer.lname, email: customer.email, address: customer.address, phone_number: customer.phone, phone_number2: customer.phone2, number_of_purchase: customer.purchases, date_registered: customer.date_added, last_purchase_date: customer.last_purchase, staff_name: customer.name}))
                        }  
                        className="btn btn-white"
                        >

                            <i className="fa fa-download fa-lg"></i> CSV Download
                        </CSVLink>
                    </div>
                </div>
            </div>

            <div className="row filter-row justify-content">
                <div className="col-sm-6 col-md-5">
                        <label className="focus-labelx">Search With</label>
                    <div className="form-group input-group">
                        <select className="form-control col-4" onChange={(e) => setFilterData(e.target.value)}>
                            <option value="name">Name</option>
                            <option value="phone">Phone</option>
                            <option value="location">Location</option>
                        </select>
                        <input type="text" className="form-control floatings col-6" placeholder="Search customer.." onChange={e => setFilterSearchInput(e.target.value)} />
                        <button onClick={searchCustomer} style={{fontSize: "0.8rem", marginLeft: "5px", backgroundColor: "#c4e00a", cursor: "pointer"}}>Search</button>
                    </div>
                    
                </div>
                
                <div className="col-sm-6 col-md-4">
                    <label className="focus-label">Select customer Category</label>
                    <div className="form-group">
                        <select className="form-control " onChange={filterCustomerCategory}>
                            <option value="all">All Customers</option>
                            {
                                categories.map(type => {
                                    return <option value={type.id}>{type.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            
            {
                selectedCustomers.length > 0 &&
                <div className="row filter-row justify-content">
                    <div className="col-sm-6 col-md-7 mb-2">
                        <button className="btn btn btn-primary mr-2" onClick={addToCategory}>
                            <i className="fa fa-plus"></i> Add to category
                        </button>

                        {/* <a href="#" className="btn btn-primary mr-2"><i className="fa fa-comment"></i> Send Message</a>

                        <a href="#" className="btn btn btn-danger "><i className="fa fa-trash"></i> Remove account</a> */}
                    </div>
                </div>
            }

            {/* Data table  */}
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        {
                            filter ?
                            <>
                            <DataTable 
                            columns={columns}
                            data={customerFilter}
                            selectableRows
                            onSelectedRowsChange={handleSelectChange}
                            progressPending={tableLoading} 
                            pagination paginationServer 
                            paginationTotalRows={totalCustomersCount}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressComponent={<Loader data={"Customers"} />}
                            paginationRowsPerPageOptions={[50, 100, 200, 500, 1000, 5000]}
                            />
                            </>
                            :
                            <DataTable 
                            columns={columns}
                            data={customersData}
                            selectableRows
                            onSelectedRowsChange={handleSelectChange}
                            progressPending={tableLoading} 
                            pagination paginationServer 
                            paginationTotalRows={totalCustomersCount}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressComponent={<Loader data={"Customers"} />}
                            paginationRowsPerPageOptions={[50, 100, 200, 500, 1000, 5000]}
                            />
                         
                        }

                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Customers;