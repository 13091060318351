
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import { Link, useNavigate } from "react-router-dom";

import user from "../../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Sale } from "../../../api";
import Toast from "../../../components/Toast";
import Cookies from "js-cookie";
import io from "socket.io-client";
import Loader from "../../../components/Loader";

const endpoint = "https://boms-api.softcollections.store";
let socket;


const StaffSales = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [sales, setSales] = useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});
    const [receptionist, setReceptionist] = useState(false);

    useEffect(() => {
        socket = io(endpoint);
        Cookies.get("sc_staff_role") === "receptionist" && setReceptionist(true);

        receptionist ?
        Sale.staffSales({staffID: Cookies.get("sc_staff")}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon:"error", title: res.message}).then(() => {
                    navigate("/staff");
                })
            }
            setSales(res.sales);
            setTotalSales(res.sales);
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon:"error", title: "Network error"}).then(() => {
                navigate("/staff")
            })
        })
        :
        Sale.staffSales({staffID: Cookies.get("sc_staff")}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon:"error", title: res.message}).then(() => {
                    navigate("/staff");
                })
            }
            setSales(res.sales);
            setTotalSales(res.sales);
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon:"error", title: "Network error"}).then(() => {
                navigate("/staff")
            })
        })
    }, [])
    
    const confirmSale = (saleID, saleRef) => {
        const data = {salesID: saleID, salesRef: saleRef, status: "Completed"};
        Sale.updateSaleStatus(data).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Sales has been marked completed"}).then(() => {
                Sale.staffSales({staffID: Cookies.get("sc_staff")}).then(res => {
                    if (res.sales) {
                        setSales(res.sales);
                        setTotalSales(res.sales);
                    }
                    
                })
            });
            socket.emit("salecomplete", data);
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error. Mark sale completed on the sales page."});
        })
      }
      
    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }
    const filterSales = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const sales = totalSales.filter(sale => {
                return (
                    new Date(sale.date_recorded.toString().slice(0,10)).getTime() >= new Date(filterDate.from).getTime() && new Date(sale.date_recorded.toString().slice(0,10)).getTime() <= new Date(filterDate.to).getTime()
                )
            })
            setSales(sales);
        }
    }

    return  (
        <BodyWrapper page={"sale"}>
            
            {
                loading && <Loader data={"Sales"} />
            }

            <div class="row">
                <div class="col-sm-4 col-3">
                    <h4 class="page-title">Sales</h4>
                </div>
                <div class="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/staff/sales-report" class="btn btn btn-primary float-right" ><i class="fa fa-address-card"></i> Sales Report</Link>
                </div>
            </div>
        
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterSales}> Filter </a>
                </div>
            </div>

            <div className="row" style={{margin: "15px 0px"}}>
                <div className="col-md-6">
                    <h4>Total Sales: {sales.length.toLocaleString("en-US").padStart(3, "0")}</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Sales Ref</th>
                                    <th>Customer Name</th>
                                    <th>Product Quantity</th>
                                    <th>Sales Rep</th>
                                    <th>Sales Date</th>
                                    <th>Sales Time</th>
                                    <th>Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            {
                            (sales && sales.length > 0) &&
                            <tbody>
                                {
                                    sales.sort((a, b) => new Date(b.date_recorded).getTime() - new Date(a.date_recorded).getTime()).map(sale => {
                                        return (
                                        <tr>
                                            <td>{sale.salesid}</td>
                                            <td><img width="28" height="28" src={user} class="rounded-circle m-r-5" alt="" /><Link to={"/staff/customers/" + sale.customerid}> {sale.fname} {sale.lname} </Link></td>
                                            <td style={{textAlign: "center"}}>{sale.products_quantity}</td>
                                            <td>{sale.s_fname} {sale.s_lname}</td>
                                            <td>{new Date(sale.date_recorded).toDateString()}</td>
                                            <td>{new Date(sale.date_recorded).toLocaleTimeString()}</td>
                                            <td>
                                                {
                                                sale.purchase_status === "Completed" ?
                                                <span class="custom-badge status-green">{sale.purchase_status}</span>
                                                :
                                                sale.purchase_status === "Ongoing" ?
                                                <span class="custom-badge status-purple">{sale.purchase_status}</span>
                                                :
                                                <span class="custom-badge status-red">{sale.purchase_status}</span>
                                                }
                                            </td>
                                            <td class="text-right">
                                                <div className="dropdown dropdown-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to={`/staff/sales/${sale.salesid}?sid=${sale.id}`}><i className="fa fa-eye m-r-5"></i> View Details</Link>

                                                        {
                                                            receptionist && sale.purchase_status !== "Completed" && (
                                                                <a className="dropdown-item" href="#" onClick={() => confirmSale(sale.id, sale.salesid)} ><i className="fa fa-check m-r-5"></i> Mark Completed</a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {/* <Link to={"/sales/" + sale.id} class="btn btn btn-primary" style={{backgroundColor: "#c4e00a", color: "black"}}>View Details</Link> */}
                                            </td>
                                        </tr>
                                        ) 
                                
                                    })
                                }
                                {/* <tr>
                                    <td>APT0002</td>
                                    <td><img width="28" height="28" src={user} class="rounded-circle m-r-5" alt="" /> Denise Stevens</td>
                                    <td style={{textAlign: "center"}}>35</td>
                                    <td>Henry Daniels</td>
                                    <td>30 Dec 2018</td>
                                    <td>10:00am - 11:00am</td>
                                    <td><span class="custom-badge status-green">Completed</span></td>
                                    <td class="text-right"><a href="#" class="btn btn btn-primary" style={{backgroundColor: "#c4e00a", color: "black"}}>View Details</a></td>
                                </tr> */}
                            </tbody>

                            }
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default StaffSales;