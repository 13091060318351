
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";

import user from "../../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Customer, Staff } from "../../../api";
import Toast from "../../../components/Toast";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";

const StaffCustomerProfile = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState({});
    const customerID = useLocation().pathname.split("/")[3];

    useEffect(() => {
        Customer.customerProfile({customerID}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/staff/customers"));
            }
            setCustomer(res.customer);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/staff/customers"));
        })
    }, [])

    const deleteAccount = () => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete ${customer.fname} ${customer.lname}'s account? All their data including their sales records, payment records and messages will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(res => {
            if (res.isConfirmed) {
                Customer.deleteCustomer({customerID}).then(res => {
                    if(res.error) {
                        return Toast.fire({icon: "error", title: res.message})
                    }
                    Toast.fire({icon: "success", title: "Customer data deleted successfully!"}).then(() => {
                        navigate("/staff/customers");
                    })
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            
            {
                loading && <Loader data={"Customer Profile"} />
            }

            <div className="content">
                <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/staff/customers/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Customers</Link>
                </div>
                    <div className="col-sm-7 col-6">
                        <h4 className="page-title">Customer Profile</h4>
                    </div>

                    <div className="col-sm-5 col-6 text-right m-b-30">
                        <Link to={"/staff/customers/" + customerID + "/edit"} className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Edit Profile</Link> &nbsp;
                        <button className="btn btn-danger btn-sm btn-rounded" onClick={deleteAccount}><i className="fa fa-trash"></i> Delete Account</button>
                    </div>
                </div>
                <div className="card-box profile-header">
                    <div className="row">
                        <div className="col-md-12">
                            {
                                customer &&
                            <div className="profile-view">
                                <div className="profile-img-wrap">
                                    <div className="profile-img">
                                        <a href="#"><img className="avatar" src={user} alt="" /></a>
                                    </div>
                                </div>
                                <div className="profile-basic">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <h3 className="user-name m-t-0 mb-0">{customer.fname} {customer.lname}</h3>
                                                <small className="text-muted">{customer.occupation}</small>
                                                <div className="staff-id">Customer ID : CUS-{customer.id && customer.id.toString().padStart(5, "0")}</div>
                                                <div className="staff-msg">
                                                    <Link to={"/staff/record-sale?cid=" + customerID} className="btn btn-primary">Record Sales</Link> &nbsp;
                                                    <Link to={"/staff/send-message?cid=" + customer.id} className="btn btn-outline-primary">Send Message</Link></div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info">
                                                <li>
                                                    <span className="title">Phone:</span>
                                                    <span className="text"><a href="#">{customer.phone} {customer.phone2 && ", " + customer.phone2}</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Email:</span>
                                                    <span className="text"><a href="#">{customer.email}</a></span>
                                                </li>
                                                <li>
                                                    <span className="title">Address:</span>
                                                    <span className="text">{customer.address}</span>
                                                </li>
                                                <li>
                                                    <span className="title">City, State:</span>
                                                    <span className="text">{customer.city && customer.city + ", "}{customer.state}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Gender:</span>
                                                    <span className="text">{customer.gender}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Occupation:</span>
                                                    <span className="text">{customer.occupation || "Not Provided"}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Date Registered:</span>
                                                    <span className="text">{new Date(customer.date_added).toDateString()}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Last Purchase:</span>
                                                    <span className="text">{customer.last_purchase != "0000-00-00" ? new Date(customer.last_purchase).toDateString() : "No purchase yet"}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Total Purchases:</span>
                                                    <span className="text">{customer.purchases}</span>
                                                </li>
                                                <li>
                                                    <span className="title">Registered by:</span>
                                                    <span className="text">{customer.s_fname} {customer.s_lname}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            }                      
                        </div>
                    </div>
                </div>
				<div className="profile-tabs">
					<ul className="nav nav-tabs nav-tabs-bottom">
						<li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">Purchases</a></li>
						<li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Messages</a></li>
					</ul>

					<div className="tab-content">
						<div className="tab-pane show active" id="about-cont">
                            <div className="row">
                                {
                                    (customer && customer.sales) &&
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <h3 className="card-title">Purchases Records</h3>
                                        <div className="experience-box">
                                            <ul className="experience-list">
                                                <li>
                                                    <div className="experience-user">
                                                        <div className="before-circle"></div>
                                                    </div>
                                                    <div className="experience-content">
                                                        {/* <div className="timeline-content">
                                                            <Link to="/staff/sales/123" className="name">#SALE-110</Link>
                                                            <div>&#8358;12,000</div>
                                                            <span className="time">June 12, 2021</span>
                                                        </div> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                }
                            </div>
						</div>
						<div className="tab-pane" id="bottom-tab2">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        
                                    (customer && customer.messages) &&
                                    <div className="card-box">
                                        <h3 className="card-title">All Messages</h3>
                                        <div className="experience-box">
                                            <ul className="experience-list">
                                                {/* <li>
                                                    <div className="experience-user">
                                                        <div className="before-circle"></div>
                                                    </div>
                                                    <div className="experience-content">
                                                        <div className="timeline-content">
                                                            <Link to="/staff/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                            <div>Welcome Message</div>
                                                            <span className="time">June 12, 2021</span>
                                                        </div>
                                                    </div>
                                                </li> */}
                                               
                                            </ul>
                                        </div>
                                    </div>

                                    }
                                </div>
                            </div>
						</div>
					</div>
				</div>
            </div>
        </BodyWrapper>
    )
}

export default StaffCustomerProfile;