
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Product } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const EditProductDetails = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: "", 
        description: "",
        quantity: 0, 
        price: 0
    });

    const [imageFile, setImageFile] = useState({});

    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const fileChange = e => {
        const uploadedFile = e.target.files[0];
        setImageFile(uploadedFile);
    }

    const productID = useLocation().pathname.split("/")[2];
    useEffect(() => {
        Product.productDetails({productID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/products"));
            }
            console.log(res.product);
            setForm(res.product);
        }).catch(err => {
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/products"));
        })

    }, [])

    const updateProduct = (e) => {
        e.preventDefault();
        setLoading(true);
        
        const productForm = new FormData();
        for (const key in form) {
            if (form.hasOwnProperty(key)) {
                productForm.append(key, form[key]);
            }
        }
        productForm.append("productID", productID)
        productForm.append("image", imageFile);

        Product.updateProduct(productForm).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message})
            }
            Toast.fire({icon: "success", title: "Product details updated succesfully"}).then(() =>navigate("/products/" + productID));
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"product"}>
        
            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/products/" + productID} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to profile</Link>
                </div>
                <div className="col-sm-12">
                    <h4 className="page-title">Update Product</h4>
                </div>
            </div>
            <form onSubmit={updateProduct}>
                <div className="card-box">
                    <h3 className="card-title">Basic Informations</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-basc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Product Name</label>
                                            <input type="text" className="form-control floating" name="name" value={form.name} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Quantity in store</label>
                                            <input type="number" className="form-control floating"  name="quantity" value={form.quantity} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Piece Selling Price</label>
                                            <input type="number" className="form-control floating" name="price" value={form.price} onChange={inputChange} />
                                        </div>
                                    </div>
                                
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Product Image</label>
                                            <input type="file" className="form-control floating"  name="imageFile" onChange={fileChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Product Description</label>
                                            <input type="text" className="form-control floating"  name="description" value={form.description} onChange={inputChange} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div className="text-center m-t-20">
                        <button className="btn btn-primary submit-btn" type="submit">{loading ? "Saving.." : "Save"}</button>
                    </div>
                </div>
                
            </form>
        </BodyWrapper>
    )
}

export default EditProductDetails;