
import BodyWrapper from "../../components/Layouts/StaffBodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Toast from "../../components/Toast";
import { Payment, Sale } from "../../api";
import Loader from "../../components/Loader";

const StaffPayments = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [totalPayments, setTotalPayments] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});
    
    useEffect(() => {
        Payment.allPayments().then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/staff"));
            }
            setPayments(res.payments);
            setTotalPayments(res.payments);
        }).catch(() => {
            setLoading(false);
            return Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/staff"));
        })
    }, [])
    
    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }

    const filterPayments = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const payments = totalPayments.filter(payment => {
                const paymentDate = new Date(payment.date_recorded.toString().slice(0,10)).getTime();
                return (
                    (paymentDate >= new Date(filterDate.from).getTime() && paymentDate <= new Date(filterDate.to).getTime())
                )
            })
            setPayments(payments);
        }
    }

    return  (
        <BodyWrapper page={"payment"}>
            
            {
                loading && <Loader data={"Payments"} />
            }

            <div class="row">
                <div class="col-sm-12">
                    <h4 class="page-title">Payments</h4>
                </div>
            </div>
            
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange}  />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterPayments}> Filter </a>
                </div>
            </div>
            <div className="row" style={{margin: "15px 0px"}}>
                <div className="col-md-6">
                    <h4>Total Payments: {payments.filter(payment => payment.status === "Confirmed").length.toLocaleString().padStart(3, "0")}</h4>
                </div>
                <div className="col-md-6">
                    <h4>Total Amount: &#8358;{
                    payments.filter(payment => payment.status === "Confirmed").length > 0 && payments.filter(payment => payment.status === "Confirmed")
                    .map(payment => payment.amount).reduce((prev, total) => prev + total).toLocaleString().padStart(5, "0")
                    }</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table datatable mb-0">
                            <thead>
                                <tr>
                                    <th>Sales ID</th>
                                    <th>Customer Name</th>
                                    <th>Recorded By</th>
                                    <th>Payment Method</th>
                                    <th>Payment Date</th>
                                    <th>Payment Time</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {
                                (payments && payments.length > 0) &&
                            <tbody>
                                {
                                    payments.map((payment) => {
                                        return (    
                                            <tr key={payment.id}>
                                                <td><Link to={"/staff/sales/" + payment.payment_ref + "?sid=" + payment.sales_id}>#{payment.payment_ref}</Link></td>
                                                <td>
                                                    <h2><Link to={`/staff/customers/${payment.customer_id}`}>{payment.c_fname} {payment.c_lname}</Link></h2>
                                                </td>
                                                <td>
                                                    <h2><Link to={`/staff/staffs/${payment.salesrep_id}`}>{payment.s_fname} {payment.s_lname}</Link></h2>
                                                </td>
                                                <td>{payment.mode}</td>
                                                <td>{new Date(payment.date_recorded).toUTCString().slice(0,16)}</td>
                                                <td>{new Date(payment.date_recorded).toLocaleTimeString()}</td>
                                                <td>&#8358;{payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                <td>
                                                    {
                                                    payment.status === "Confirmed" ?
                                                    <span class="custom-badge status-green">{payment.status}</span>
                                                    :
                                                    payment.status === "pending" ?
                                                    <span class="custom-badge status-purple">{payment.status}</span>
                                                    :
                                                    <span class="custom-badge status-red">{payment.status}</span>
                                                    }
                                                </td>
                                                <td class="text-right">
                                                    <div class="dropdown dropdown-action">
                                                        <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <Link class="dropdown-item" to={`/staff/payments/${payment.id}?sid=${payment.payment_ref}`} data-toggle="modal" data-target="#delete_appointment"><i class="fa fa-eye m-r-5"></i> View</Link>
                                                            {/* <a class="dropdown-item" href="edit-appointment.html"><i class="fa fa-check m-r-5"></i> Approve</a> */}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                
                                {/* <tr>
                                    <td><a href="invoice-view.html">#INV-0001</a></td>
                                    <td><a href="invoice-view.html">#REF-0001</a></td>
                                    <td>
                                        <h2><a href="#">Charles Ortega</a></h2>
                                    </td>
                                    <td>Paypal</td>
                                    <td>8 Aug 2017</td>
                                    <td>09:00:PM</td>
                                    <td>$500</td>
                                    <td><span class="custom-badge status-red">Pending</span></td>
                                    <td class="text-right">
                                        <div class="dropdown dropdown-action">
                                            <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" href="edit-appointment.html"><i class="fa fa-eye m-r-5"></i> View</a>
                                                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#delete_appointment"><i class="fa fa-check m-r-5"></i> Approve</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default StaffPayments;