
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/softlogo.png";
import { useState } from "react";
import Toast from "../components/Toast";
import Cookies from "js-cookie";
import { Staff } from "../api";

const Login = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({email: "", password: ""});
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const login = e => {
        e.preventDefault();
        setLoading(true);
        Staff.login(form).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({title: res.message, icon: "error"});
            }
            Cookies.set("sc_staff", res.token)
            Cookies.set("sc_staff_role", res.staff.role);
            Cookies.set("sc_staff_name", res.staff.fname);
            Toast.fire({title: "Logged in", icon: "success"}).then(() => {
                if (res.staff.role === "admin") {
                    navigate("/dashboard");
                } else {
                    navigate("/staff/dashboard");
                }
            } );
        }).catch(err => {
            setLoading(false);
            Toast.fire({title: "Network error", icon: "error"});
        })
    }

    return  (
    <div className="main-wrapper account-wrapper">
        <br /> <br /><br />
    <div className="account-page">
        <div className="account-center">
            <div className="account-box">
                <form className="form-signin" onSubmit={login}>
                    <div className="account-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" autoFocus="" name="email" className="form-control" onChange={inputChange} value={form.email} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" name="password" onChange={inputChange} value={form.password} />
                    </div>
                    <div className="form-group text-right">
                        {/* <Link to="/forgot-password">Forgot your password?</Link> */}
                    </div>
                    <div className="form-group text-center">
                        <button type="submit" className="btn btn-primary account-btn">{loading ? "Logging in.." : "Login"}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
    )
}

export default Login;