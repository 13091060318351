import http from "axios";
import Cookies from "js-cookie";

// const serverurl = "https://softcollectionsapi.prodiscale.com";
const serverurl = "https://softcollectionsapi.prodiscale.com";
// const serverurl = "http://localhost:4000";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("sc_staff");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;
const request = {
    get: (url, params) => http.get(`${serverurl}${url}`, { params }, requestOptions() ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const Staff = {
    login: (params)=> request.post("/staffs/login", params),
    allStaffs: (params) => request.post("/staffs/allStaffs", params),
    addStaff: (params) => request.post("/staffs/addStaff", params),
    updateStaff: (params) => request.post("/staffs/updateStaff", params),
    profile: (params) => request.post("/staffs/profile", params),
    myprofile: (params) => request.post("/staffs/myprofile", params),
    deactivateAccount: (params) => request.post("/staffs/removeStaff", params),
    reactivateAccount: (params) => request.post("/staffs/reactivateStaff", params),
    adminDashboard: (params) => request.post("/staffs/adminDashboard", params),
    staffDashboard: (params) => request.post("/staffs/staffDashboard", params),
    monthlyData: (params) => request.post("/staffs/monthlyData", params),
}

const Customer = {
    addCustomer: (params) => request.post("/customers/addCustomer", params),
    customerProfile: (params) => request.post("/customers/customerProfile", params),
    updateCustomer: (params) => request.post("/customers/updateCustomer", params),
    allCustomers: (params) => request.post("/customers/allCustomers", params),
    allCustomersCount: (params) => request.post("/customers/allCustomersCount", params),
    getCustomers: (params) => request.post("/customers/getCustomers", params),
    deleteCustomer: (params) => request.post("/customers/deleteCustomer", params),
    getCategories: (params) => request.post("/customers/getCategories", params),
    addCategory: (params) => request.post("/customers/addCategory", params),
    deleteCategory: (params) => request.post("/customers/deleteCategory", params),
    addCustomersToCategory: (params) => request.post("/customers/addCustomersToCategory", params),
}

const Sale = {
    recordSale: (params) => request.post("/sales/", params),
    allSales: (params) => request.post("/sales/allSales", params),
    getSales: (params) => request.post("/sales/getSales", params),
    salesCount: (params) => request.post("/sales/salesCount", params),
    saleDetails: (params) => request.post("/sales/saleDetails", params),
    staffSales: (params) => request.post("/sales/staffSales", params),
    updateSaleStatus: (params) => request.post("/sales/updateSaleStatus", params),
    deleteSale: (params) => request.post("/sales/deleteSale", params),
    salesReport: (params) => request.post("/sales/salesReport", params)
}

const Payment = {
    allPayments: (params) => request.post("/payments/allPayments", params),
    paymentDetails: (params) => request.post("/payments/paymentDetails", params),
    updatePaymentStatus: (params) => request.post("/payments/updatePaymentStatus", params),
}

const Expense = {
    addExpense: (params) => request.post("/expenses/recordExpense", params),
    allExpenses: (params) => request.post("/expenses/allExpenses", params),
    deleteExpense: (params) => request.post("/expenses/deleteExpense", params),
}

const Message = {
    sendMessage: (params) => request.post("/messages/sendMessage", params),
    sendCategoryMessage: (params) => request.post("/messages/sendCategoryMessage", params),
    allMessages: (params) => request.post("/messages/allMessages", params),
    staffMessages: (params) => request.post("/messages/staffMessages", params),
    messageDetails: (params) => request.post("/messages/messageDetails", params),
    updateMessageStatus: (params) => request.post("/messages/updateMessageStatus", params),
    deleteMessage: (params) => request.post("/messages/deleteMessage", params),
}

const Notification = {
    allNotification: (params) => request.post("/notifications/allNotification", params),
    unreadNotification: (params) => request.post("/notifications/unreadNotification", params),
    markOneNotificationRead: (params) => request.post("/notifications/markOneNotificationRead", params),
    markNotificationsRead: (params) => request.post("/notifications/markNotificationsRead", params)
}


const Product = {
    addProduct: (params) => request.post("/products/addProduct", params),
    productDetails: (params) => request.post("/products/productDetails", params),
    updateProduct: (params) => request.post("/products/updateProduct", params),
    allProducts: (params) => request.post("/products/allProducts", params),
    deleteProduct: (params) => request.post("/products/deleteProduct", params)
}


export {Staff, Customer, Sale, Payment, Expense, Message, Notification, Product, serverurl};