
import BodyWrapper from "../../components/Layouts/StaffBodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Staff } from "../../api";
import Toast from "../../components/Toast";
import Cookies from "js-cookie";
import Loader from "../../components/Loader";

const staffRolesCodes = {sales_rep: "Sales Representative", receptionist: "Receptionist", admin: "Administrator"};

const StaffDashboard = () => {

        const navigate = useNavigate();

        const [loading, setLoading] = useState(true);
        const [data, setData] = useState({sales: [], customers: [], recentSales: []});
    
        useEffect(() => {
            Staff.staffDashboard().then((res) => {
                setLoading(false);
                if(res.error){
                    return Toast.fire({icon: "error", title: res.message}).then(() => {
                        navigate("/login")
                    }) 
                }
                setData(res.dashboardDetails);
            }).catch(err => {
                setLoading(false);
                Toast.fire({icon: "error", title: "Network error. Log out and relogin."}).then(() => {
                    Cookies.remove("sc_staff");
                    navigate("/login")
                })
            })
        }, [])

    return (
        <BodyWrapper page={"dashboard"}>
        {
            loading && <Loader data={"Dashboard data"} />
        }
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/staff/customers"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg2"><i className="fa fa-users"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{data.customers.length.toLocaleString().padStart(3, "0")}</h3>
                                <span className="widget-title2">Customers <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/staff/sales"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg3"><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{data.sales.length.toLocaleString().padStart(3, "0")}</h3>
                                <span className="widget-title3">Sales <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            
            {/* --Charts removed here-- */}

            <div className="row">
                <div className="col-12 col-md-6 col-lg-8 col-xl-8">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">Recent Sales</h4> <Link to="/staff/sales" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="d-none">
                                        <tr>
                                            <th>Customer Name</th>
                                            <th>Recorded By</th>
                                            <th>Sales Time</th>
                                            <th className="text-right">Status</th>
                                        </tr>
                                    </thead>
                                    {
                                        data.recentSales.length > 0 && 
                                    <tbody>
                                        {
                                            data.recentSales.map(sale => {
                                                return (
                                                        
                                                    <tr key={sale.id}>
                                                        <td style={{minWidth: "200px"}}>
                                                            <Link className="avatar" to={"/staff/customers/" + sale.customerid}>{sale.c_fname[0]}</Link>
                                                            <h2><Link to={"/staff/customers/" + sale.customerid}>{sale.c_fname} {sale.c_lname} <span>3:30PM</span></Link></h2>
                                                        </td>                 
                                                        <td>
                                                            <h5 className="time-title p-0">Recorded By</h5>
                                                            <p>{sale.s_fname} {sale.s_lname}</p>
                                                        </td>
                                                        <td>
                                                            <h5 className="time-title p-0">Product</h5>
                                                            <p>{sale.products_quantity} pieces</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <Link to={`/staff/sales/${sale.salesid}?sid=${sale.id}`} className="btn btn-outline-primary take-btn">View Details</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-12 col-md-9 col-lg-9 col-xl-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">New Customers </h4> <Link to="/staff/customers" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-block">
                            <div className="table-responsive">
                                <table className="table mb-0 new-patient-table">
                                    <tbody>
                                        {
                                            data.customers.map((customer, i) => {
                                                return (
                                                    (i < 5) && (<tr>
                                                        <td>
                                                            <img width="28" height="28" className="rounded-circle" src={user} alt="" /> 
                                                            <h2>{customer.fname} {customer.lname}</h2>
                                                        </td>
                                                        <td>{customer.email}</td>
                                                        <td>{customer.phone}</td>
                                                        <td>
                                                            <Link to={"/staff/customers/" + customer.id} className="btn btn-outline-primary take-btn float-right">View Profile</Link>
                                                        </td>
                                                    </tr>)
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default StaffDashboard;