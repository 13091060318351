
import { Link } from "react-router-dom";

const SalesDropdown = ({salesid, id, salesrep_id, customerid, deleteSale, confirmSale}) => {
    return (
        <div className="dropdown dropdown-action">
            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
            <div className="dropdown-menu dropdown-menu-right" style={{backgroundColor: "#c4e00a"}}>
                <Link className="dropdown-item" to={`/sales/${salesid}?sid=${id}`}><i className="fa fa-eye m-r-5"></i> View Details</Link>
                <a className="dropdown-item" href="#" onClick={() => confirmSale(id, salesid)}><i className="fa fa-check m-r-5"></i> Mark Completed</a>
                <a 
                className="dropdown-item" 
                href="#" 
                onClick={() => deleteSale({id, saleid: salesid, staffid: salesrep_id, customerid})}
                >
                    <i className="fa fa-trash m-r-5"></i> Delete Sale
                </a>
            </div>
        </div>
    )
}

export default SalesDropdown;