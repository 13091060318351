
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import logo from "../../assets/img/softlogo.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Toast from "../../components/Toast";
import { Payment } from "../../api";
import Payments from "./Payments";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const PaymentInfo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [payment, setPayment] = useState({});
    const paymentID = location.pathname.split("/")[2];
    const saleID = location.search.split("=")[1];

    useEffect(() => {
        if(!paymentID || !saleID) {
            return Toast.fire({icon: "error", title: "Data error. Go back a step and refresh."}).then(() => navigate("/payments"));
        }
        Payment.paymentDetails({saleID, paymentID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message})
            }
            setPayment(res.paymentDetails);
        }).catch((err) => {
            console.log(err);
            Toast.fire({icon: "error", title: "Data error. Go back a step and refresh."}).then(() => navigate("/payments"))
        })
    }, [])

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
      }

    return  (
        <BodyWrapper  page={"payment"}>
            <div className="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/payments" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Payments</Link>
                    </div>
                <div className="col-sm-5 col-4">
                    <h4 className="page-title">Payment Reciept</h4>
                </div>
                <div className="col-sm-7 col-8 text-right m-b-30">
                    <div className="btn-group btn-group-sm">
                        {/* <button className="btn btn-white">CSV</button>
                        <button className="btn btn-white">PDF</button> */}
                        <button className="btn btn-white" onClick={Print}><i className="fa fa-print fa-lg"></i> Print</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card" id="printablediv">
                        <div className="card-body">
                            <div className="row custom-invoice">
                                <div className="col-6 col-sm-6 m-b-20">
                                    <img src={logo} className="inv-logo" alt="" />
                                    <h4>Soft Collections</h4>
                                    <ul className="list-unstyled">
                                        <li></li>
                                        <li></li>
                                        <li>No 1, Olayiwola Road,</li>
                                        <li>New oko Oba, Abule egba</li>
                                        <li>Lagos.</li>
                                        <li>GST No: 08107562438, 08147936224</li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 m-b-20">
                                    <div className="invoice-details">
                                        <h3 className="text-uppercase">#REF-{payment.id && payment.id.toString().padStart(4,"0")}</h3>
                                        <ul className="list-unstyled">
                                            <li>Date: <span> {payment.date_recorded && new Date(payment.date_recorded).toDateString().slice(8,15).replace(" ", " " + months[new Date(payment.date_recorded).getMonth()] + ", " )}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    
                                        <h5>Reciept to:</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <h5><strong>{payment.c_fname} {payment.c_lname}</strong></h5>
                                            </li>
                                            <li><span>{payment.address}</span></li>
                                            <li>{payment.city && payment.city + ", "}{payment.state} State</li>
                                            <li>{payment.phone}</li>
                                            <li>{payment.email}</li>
                                        </ul>
                                    
                                </div>
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    <div className="invoices-view">
                                        <span className="text-muted">Payment Details:</span>
                                        <ul className="list-unstyled invoice-payment-details">
                                            <li>
                                                <h5>Total Amount: <span className="text-right">&#8358;{payment.amount && payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></h5>
                                            </li>
                                            <li>Payer Name: <span>{payment.payer_name}</span></li>
                                            <li>Payment Method: <span>{payment.mode}</span></li>
                                            <li>Payment Date: <span>{payment.payment_date && new Date(payment.payment_date).toDateString().slice(8,15).replace(" ", " " + months[new Date(payment.payment_date).getMonth()] + ", " )}</span></li>
                                            {/* <li>Country: <span>United Kingdom</span></li> */}
                                            {/* <li>City: <span>London E1 8BF</span></li>
                                            <li>Address: <span>3 Goodman Street</span></li>
                                            <li>IBAN: <span>KFH37784028476740</span></li>
                                            <li>SWIFT code: <span>BPT4E</span></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ITEM(S)</th>
                                            <th>DESCRIPTION</th>
                                            <th>QUANTITY</th>
                                            <th>COST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        payment.version === 2 ?
                                        payment.items.map(item => {
                                            return (
                                            <tr>
                                                <td></td>
                                                <td>{item.p_name}</td>
                                                <td>&#8358;{item.price.toLocaleString()}</td>
                                                <td>{item.quantity}</td>
                                                <td>&#8358;{Math.ceil(item.quantity * item.price).toLocaleString()}</td>
                                            </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td></td>
                                            <td>{payment.products_purchase}</td>
                                            <td>{payment.purchase_description}</td>
                                            <td>{payment.products_quantity}</td>
                                            <td>&#8358;{payment.amount && payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                        </tr>
                                    }
                                        {/* <tr>
                                            <td>2</td>
                                            <td>T Shirt</td>
                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                            <td>$12</td>
                                            <td>1</td>
                                            <td>$12</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Hood</td>
                                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                            <td>$100</td>
                                            <td>1</td>
                                            <td>$100</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="row invoice-payment">
                                    <div className="col-sm-7">
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="m-b-20">
                                            <h6>Total paid</h6>
                                            <div className="table-responsive no-border">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td className="text-right">&#8358;{payment.amount && payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tax: <span className="text-regular">(0%)</span></th>
                                                            <td className="text-right">&#8358;0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total:</th>
                                                            <td className="text-right text-primary">
                                                                <h5>&#8358;{payment.amount && payment.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div><br /><br /><br />
                                <div className="invoice-info">
                                    <h5>Terms and Conditions</h5>
                                    <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero, eu finibus sapien interdum vel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default PaymentInfo;