
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import user from "../../../assets/img/user.jpg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Message } from "../../../api";
import Toast from "../../../components/Toast";
import Loader from "../../../components/Loader";

const StaffMessages = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [allMessages, setAllMessages] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});

    useEffect(() => {
        Message.staffMessages().then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/staff"));
            }
            setMessages(res.messages);
            setAllMessages(res.messages);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/staff"))
        })
    }, [])

    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }
    const filterMessages = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const messages = allMessages.filter(message => {
                const messageDate = new Date(message.date_initiated.toString().slice(0,10)).getTime();
                return (
                    (messageDate >= new Date(filterDate.from).getTime() && messageDate <= new Date(filterDate.to).getTime())
                )
            })
            setMessages(messages);
        }
    }

    return  (
        <BodyWrapper page={"message"}>
        {
            loading && <Loader data={"Messages"} />
        }
            <div class="row">
                <div class="col-sm-4 col-3">
                    <h4 class="page-title">Messages</h4>
                </div>
                <div class="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/staff/send-message" class="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#c4e00a", color: "black"}}><i class="fa fa-plus"></i> Send New Message</Link>
                </div>
            </div>
        
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterMessages}> Filter </a>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Message Title</th>
                                    <th>Sent By</th>
                                    <th>Number of recipient(s)</th>
                                    <th>Message Type</th>
                                    <th>Date Sent</th>
                                    <th>Time Init.</th>
                                    <th>Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    messages.map(message => {
                                        return (
                                            <tr>
                                                <td>{message.message_reason}</td>
                                                <td><img width="28" height="28" src={user} class="rounded-circle m-r-5" alt="" /> {message.fname} {message.lname}</td>
                                                <td style={{textAlign: "center"}}>
                                                    {message.recipients.toLowerCase().indexOf("all") > -1 ? "All Customers" : 1 }
                                                </td>
                                                <td>{message.message_type}</td>
                                                <td>{new Date(message.date_initiated).toUTCString().slice(0,16)}</td>
                                                <td>{new Date(message.date_initiated).toLocaleTimeString()}</td>
                                                <td>
                                                    {
                                                        message.status.toLowerCase() === "sent" ?
                                                        <span class="custom-badge status-green">{message.status}</span>
                                                        :
                                                        message.status.toLowerCase() === "pending" ?
                                                        <span class="custom-badge status-purple">{message.status}</span>
                                                        :
                                                        <span class="custom-badge status-red">{message.status}</span>
                                                    }
                                                </td>
                                                <td class="text-right"><Link to={"/staff/messages/" + message.id} class="btn btn btn-primary" style={{backgroundColor: "#c4e00a", color: "black"}}>View</Link></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default StaffMessages;