
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Customer } from "../../../api";
import BodyWrapper from "../../../components/Layouts/StaffBodyWrapper";
import Toast from "../../../components/Toast";

const AddCustomer = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({fname: "", lname: "", phone: "", altPhone: "", email: "", gender: "", address: "", city: "", state: "", state: "", occupation: "", category: ""});
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        Customer.getCategories().then(res => {
            if (res.error) {
                return Toast.fire({icon: "error", title: "Network error. Go back a step and reload."}).then(() => {
                    navigate("/staff");
                })
            }
            setCategories(res.categories);
        })
    })

    const registerCustomer = (e) => {
        e.preventDefault();
        setLoading(true);
        Customer.addCustomer(form).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message });
            }
            Toast.fire({icon: "success", title: res.message }).then(() => {
                navigate("/staff/customers/" + res.customerID);
            });
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }



    return  (
        <BodyWrapper page={"customer"}>
            
            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/staff/customers/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Customers</Link>
                </div>
                <div className="col-lg-8 offset-lg-2">
                    <h4 className="page-title">Add Customer</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form onSubmit={registerCustomer}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>First Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="fname" type="text" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Last Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="lname" type="text" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Active Phone <span className="text-danger">*</span></label>
                                    <input className="form-control" name="phone" type="tel" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>WhatsApp Phone</label>
                                    <input className="form-control" name="altPhone" type="tel"  onChange={inputChange}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Email </label>
                                    <input className="form-control" type="email" name="email" onChange={inputChange}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group gender-select">
                                    <label className="gen-label">Gender: <span className="text-danger">*</span></label>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" name="gender" className="form-check-input" value={"Male"}  onChange={inputChange}/>Male
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" name="gender" className="form-check-input" value={"Female"}  onChange={inputChange}/>Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control " name="address"  onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" className="form-control" name="city"  onChange={inputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>State <span className="text-danger">*</span></label>
                                            <select className="form-control selects" name="state" onChange={inputChange}>
                                                <option value="">- Select -</option>
                                                <option value="Abuja FCT">Abuja FCT</option>
                                                <option value="Abia">Abia</option>
                                                <option value="Adamawa">Adamawa</option>
                                                <option value="Akwa Ibom">Akwa Ibom</option>
                                                <option value="Anambra">Anambra</option>
                                                <option value="Bauchi">Bauchi</option>
                                                <option value="Bayelsa">Bayelsa</option>
                                                <option value="Benue">Benue</option>
                                                <option value="Borno">Borno</option>
                                                <option value="Cross River">Cross River</option>
                                                <option value="Delta">Delta</option>
                                                <option value="Ebonyi">Ebonyi</option>
                                                <option value="Edo">Edo</option>
                                                <option value="Ekiti">Ekiti</option>
                                                <option value="Enugu">Enugu</option>
                                                <option value="Gombe">Gombe</option>
                                                <option value="Imo">Imo</option>
                                                <option value="Jigawa">Jigawa</option>
                                                <option value="Kaduna">Kaduna</option>
                                                <option value="Kano">Kano</option>
                                                <option value="Katsina">Katsina</option>
                                                <option value="Kebbi">Kebbi</option>
                                                <option value="Kogi">Kogi</option>
                                                <option value="Kwara">Kwara</option>
                                                <option value="Lagos">Lagos</option>
                                                <option value="Nassarawa">Nassarawa</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Ogun">Ogun</option>
                                                <option value="Ondo">Ondo</option>
                                                <option value="Osun">Osun</option>
                                                <option value="Oyo">Oyo</option>
                                                <option value="Plateau">Plateau</option>
                                                <option value="Rivers">Rivers</option>
                                                <option value="Sokoto">Sokoto</option>
                                                <option value="Taraba">Taraba</option>
                                                <option value="Yobe">Yobe</option>
                                                <option value="Zamfara">Zamfara</option>
                                                <option value="outside_nigeria">Outside Nigeria</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                form.state == "outside_nigeria" &&
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>State </label>
                                    <input className="form-control" name="state" type="text" />
                                </div>
                            </div>
                            }
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Occupation </label>
                                    <input className="form-control" name="occupation" type="text" value={form.occupation} onChange={inputChange} />
                                </div>
                            </div>
                            
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Customer category <span className="text-danger"></span></label>
                                    <select className="form-control selects" name="category" onChange={inputChange}>
                                        <option value="">- Select -</option>
                                        {
                                            categories && categories.map(category => {
                                                return <option value={category.id}>{category.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="m-t-20 text-center">
                            <button className="btn btn-primary submit-btn">{loading ? "Adding customer..." : "Add Customer"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default AddCustomer;