
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import logo from "../../assets/img/softlogo.png";
import user from "../../assets/img/user.jpg";
import { Notification, Sale } from "../../api";
import Toast from "../Toast";
import io from "socket.io-client";
import notificationSound from "../../assets/audio/notification.mp3";
import { useRef } from "react";

const endpoint = "https://boms-api.softcollections.store";

let socket;
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  ReactModal.setAppElement('#root');

const StaffTopNav = () => {
    const logout= (e) => {
        e.preventDefault();
        Cookies.remove("sc_staff");
        navigate("/login");
    }

    const audioRef = useRef(null);

    const navigate = useNavigate();
    const [saleDetails, setSaleDetails] = useState({products_purchase: 0, products_quantity: "", purchase_description: "", amount: ""});
    const [openModal, setOpenModal] = useState(false);
    const [staffName, setStaffName] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [isReceptionist, setIsReceptionist] = useState(false);

    useEffect(() => {
        socket = io(endpoint);

        const token = Cookies.get("sc_staff");
        if(!token  || token.length < 1) {
            return navigate("/login");
        }
        setStaffName(Cookies.get("sc_staff_name"));

        if (Cookies.get("sc_staff_role") === "receptionist") {
            setIsReceptionist(true);
        }

        socket.on("update_sales_status", (data) => {
            if (data.salesid && data.payment && Cookies.get("sc_staff_role") === "receptionist") {
                setOpenModal(true);
                setSaleDetails(data);
                audioRef.current.play();
                Notification.allNotification().then(res => {
                    if(res.success) {
                        setNotifications(res.notifications);
                    }
                })
            }
        })
        
        Notification.allNotification().then(res => {
            if(res.success) {
                setNotifications(res.notifications);
            }
        })
        
    }, [])

    const confirmSale = () => {
        const data = {salesID: saleDetails.id, salesRef: saleDetails.salesid, status: "Completed"};
        Sale.updateSaleStatus(data).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Sales has been marked completed"}).then(() => setOpenModal(false));
            socket.emit("salecomplete", data);
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error. Mark sale completed on the sales page. Ref: " + saleDetails.salesid})
            .then(() => setOpenModal(false));
        })
      }

      const declineSale = () => {
        const data = {salesID: saleDetails.id, salesRef: saleDetails.salesid, status: "Cancelled"};
        Sale.updateSaleStatus(data).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Sales has been Cancelled"});
        }).catch(() => {
            Toast.fire({icon: "error", title: "Network error. Cancel sale on the sales page. Ref: " + saleDetails.salesid});
        })
      }

  //Mark All Notifications as read
  const markAllNotificationsRead = e => {
    e.preventDefault();
    Notification.markNotificationsRead().then((res) => {
        Notification.allNotification().then(res => {
            res.notifications && setNotifications(res.notifications);
        })
    })
  }

  //Mark one notification as read
  const markOneNotificationsRead = (notificationID) => {
    
    Notification.markOneNotificationRead({notificationID}).then((res) => {
        console.log(res);
        Notification.allNotification().then(res => {
            res.notifications && setNotifications(res.notifications);
        })
    }).catch(err => {
        console.log();
    })
  }


    return (
        
        <div className="header" style={{backgroundColor: "#c4e00a"}}>
			<div className="header-left">
				<a href="/staff/" className="logo">
					<img src={logo} width="70" height="60" alt="" /> <span>Soft Collections</span>
				</a>
			</div>
			<a id="toggle_btn" href="#"><i className="fa fa-bars"></i></a>

            <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar" ><i className="fa fa-bars"></i></a>

            <ul className="nav user-menu float-right">
                <li className="nav-item dropdown d-none d-sm-block">
                    { isReceptionist && 
                    <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <i className="fa fa-bell-o"></i> 
                        {
                            notifications.filter(noti => noti.read_status == 0).length > 0 &&
                            <span className="badge badge-pill bg-danger float-right">
                            {
                                notifications.filter(noti => noti.read_status == 0).length
                            }
                            </span>
                        }
                    </a> }
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <span>Notifications</span>
                        </div>
                        <div className="drop-scroll">
                            {
                                (notifications && notifications.length > 0) &&
                                <ul className="notification-list">
                                    {
                                        notifications.map(notification => {
                                            return (
                                            <li className="notification-message" key={notification.id}>
                                                <Link to={notification.url} onClick={() => markOneNotificationsRead(notification.id)}>
                                                    <div className="media">
                                                        <span className="avatar">
                                                            <img alt="John Doe" src={user} className="img-fluid" />
                                                        </span>
                                                        <div className="media-body" style={{fontWeight: notification.read_status ? "normal" : "bold"}}>
                                                            <p className="noti-details">{notification.info}</p>
                                                            <p className="noti-time"><span className="notification-time">{new Date(notification.date).toDateString() } | {new Date(notification.date).toLocaleTimeString() }</span></p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                        </div>
                        <div className="topnav-dropdown-footer">
                            <a href="#" onClick={markAllNotificationsRead}>Mark all as read</a>
                        </div>
                    </div>
                </li>
                {/* <li className="nav-item dropdown d-none d-sm-block">
                    <a href="#" id="open_msg_box" className="hasnotifications nav-link"><i className="fa fa-comment-o"></i> <span className="badge badge-pill bg-danger float-right">8</span></a>
                </li> */}
                <li className="nav-item dropdown has-arrow">
                    <a href="#" className="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span className="user-img">
							<img className="rounded-circle" src={user} width="24" alt="Admin" />
						</span>
						<span>{staffName}</span>
                    </a>
					<div className="dropdown-menu">
						<Link className="dropdown-item" to="/staff/myprofile">My Profile</Link>
						{/* <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
						<a className="dropdown-item" href="settings.html">Settings</a> */}
						<a className="dropdown-item" href="#" onClick={logout}>Logout</a>
					</div>
                </li>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item" to="/staff/myprofile">My Profile</Link>
                    <a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                </div>
            </div>

            
            <ReactModal isOpen={openModal} style={customStyles} contentLabel="Example Modal" >
                <h3>Hello, Receptionist</h3>
                <p>Please deliver this product and mark it confirmed:</p>
                <p>
                    <p>Customer Name: <b>{saleDetails.c_fname} {saleDetails.c_lname}</b> </p>
                    Quantity: <b>{saleDetails.products_quantity}</b> <br />
                    Products: <b>{saleDetails.products_purchase}</b> <br />
                    Description: <b>{saleDetails.purchase_description}</b> <br /><br />
                    Sales recorded by: <b>{saleDetails.s_fname} {saleDetails.s_lname}</b> <br />
                    Payment Status: <b>{ saleDetails.payment && saleDetails.payment.status }</b> <br />
                    Payment Date and Time: <b>{new Date(saleDetails.date_recorded).toDateString() + " | " + new Date(saleDetails.date_recorded).toLocaleTimeString() }</b> <br />
                </p>
                <h4>Sale Reference: {saleDetails.salesid}</h4>
                <button className="btn btn-primary" onClick={confirmSale}><i className="fa fa-check"></i> Yes, Confirmed</button>
                &nbsp; &nbsp;
                <button className="btn btn-danger" onClick={declineSale}><i className="fa fa-cross"></i> No, decline</button>
            </ReactModal>

            <audio src={notificationSound} typeof="audio/mp3" ref={audioRef}></audio>
        </div>
    )
}

export default StaffTopNav;